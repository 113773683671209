import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/audios',
    name: 'audios',
    component: () => import('../views/AudiosView.vue')
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('../views/CalendarView.vue')
  },
  {
    path: '/ibo',
    name: 'ibo',
    component: () => import('../views/IBOView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/player',
    name: 'player',
    component: () => import('../views/PlayerView.vue')
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import('../views/ReportsView.vue')
  },
  {
    path: '/salesorder',
    name: 'salesorder',
    component: () => import('../views/SalesOrderView.vue')
  },
  {
    path: '/salesorderaddress',
    name: 'salesorderaddress',
    component: () => import('../views/SalesOrderAddressView.vue')
  },
  {
    path: '/salesordersummary',
    name: 'salesordersummary',
    component: () => import('../views/SalesOrderSummaryView.vue')
  },
  {
    path: '/salesorderccpayment',
    name: 'salesorderccpayment',
    component: () => import('../views/SalesOrderCCPaymentView.vue')
  },
  {
    path: '/store',
    name: 'store',
    component: () => import('../views/StoreView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
