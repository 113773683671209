<template>
    <v-app-bar
        color="#8B4B01"
        density="compact"
      >
      <v-avatar size="40" class="mx-3">
        <v-img
          src="logo.png"
          @click.prevent="openDrawer"
        >
        </v-img>
      </v-avatar>
        <v-app-bar-title class="app-bar-title">{{ getPageTitle }}</v-app-bar-title>
        <v-btn v-if="productsOnCart > 0" flat color="#8B4B01" class="mr-3 text-white" to="/salesorder" icon="fa-solid fa-cart-shopping"></v-btn>
    </v-app-bar>
</template>

<script>
export default {
  methods: {
    openDrawer() {
      console.log('logo clicked')
      this.$store.dispatch('updateShowDrawer')
    }
  },
  computed: {
    getPageTitle() {
      return this.$store.getters.pageTitle
    },
    productsOnCart() {
      return this.$store.getters.productsOnCart
    }
  }
}
</script>

<style scoped>
  .image {
    border: 1px;
    mix-blend-mode: multiply;
  }

  .app-bar-title {
    color: white;
  }

  .shopping-cart-selected {
    background-color: yellow;
    color: white;
  }
</style>