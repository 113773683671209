<template>
  <v-app>
    <NavBar />
    <SideBar />
    <v-container fluid class="main-background" >
      <div>
        <h5>Próximos eventos</h5>
        <v-table height="200px" density="compact">
          <thead>
            <tr>
              <th class="text-center">
                Fecha
              </th>
              <th class="text-left">
                Evento
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="event in events"
              :key="event.id"
            >
              <td class="text-center">{{ event.date }}</td>
              <td class="text-left">{{ event.name }}</td>
            </tr>
          </tbody>
        </v-table>
      </div>
      <div>
        <h5>Ultimas órdenes</h5>
        <v-table height="200px" density="compact">
          <thead>
            <tr>
              <th class="text-center">
                Fecha
              </th>
              <th class="text-center">
                IBO #
              </th>
              <th class="text-tipo">
                Tipo
              </th>
              <th class="text-right">
                Monto
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="order in orders"
              :key="order.id"
            >
              <td class="text-center">{{ order.date }}</td>
              <td class="text-left">{{ order.iboNumber }}</td>
              <td class="text-center">{{ order.orderType }}</td>
              <td class="text-right">{{ formatCurrency(order.amount) }}</td>
            </tr>
          </tbody>
        </v-table>
      </div>
      <v-divider></v-divider>
      <div class="mt-2">
        <v-row>
          <v-col>
            <button v-ripple class="elevation-0 py-6 px-4 dashboard-btn" @click.prevent="moveTo('/store')">
              <v-icon top>fa-solid fa-cart-shopping</v-icon>
              <p>Orden Fija</p>
            </button>
          </v-col>       
          <v-col>
            <button v-ripple class="elevation-0 py-6 px-4 dashboard-btn" @click.prevent="moveTo('/audios')">
              <v-icon top>fa-solid fa-music</v-icon>
              <p>Audioteca</p>
            </button>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </v-app>
</template>

<script>
import { defineComponent } from 'vue';


// Components
import SideBar from '@/components/SideBar.vue';
import NavBar from '@/components/NavBar.vue';

export default defineComponent({
  name: 'HomeView',
  components: {
    SideBar,
    NavBar
  },
  data() {
    return {
      events: [
          {
            id: 1,
            date: '12/06/2022',
            name: 'Open Fresno, CA',
            calories: 159,
          },
          {
            id: 2,
            date: '12/06/2022',
            name: 'Open Fresno, CA',
          },
          {
            id: 3,
            date: '12/06/2022',
            name: 'Open San Jose, CA',
          },
          {
            id: 4,
            date: '12/06/2022',
            name: 'Open Chicago, IL',
          },
          {
            id: 5,
            date: '12/06/2022',
            name: 'Open Bronx, NY',
          },
          {
            id: 6,
            date: '12/06/2022',
            name: 'Open Stockton, CA',
          },
          {
            id: 7,
            date: '12/06/2022',
            name: 'Open Houston, TX',
          },
          {
            id: 8,
            date: '12/06/2022',
            name: 'Open San Diego, CA',
          },
          {
            id: 9,
            date: '12/06/2022',
            name: 'Open Bakersfield, CA',
          },
          {
            id: 10,
            date: '12/06/2022',
            name: 'Open Madera, CA',
          },
        ],
      orders: [
        {
          id: 1,
          date: '12/06/2022',
          iboNumber: 123456,
          orderType: 'AD',
          amount: 1234
        },
        {
          id: 2,
          date: '12/07/2022',
          iboNumber: 123457,
          orderType: 'BF',
          amount: 1235
        },
        {
          id: 3,
          date: '12/08/2022',
          iboNumber: 123458,
          orderType: 'BD',
          amount: 1236
        },
        {
          id: 4,
          date: '12/09/2022',
          iboNumber: 123459,
          orderType: 'LF',
          amount: 1237
        },
        {
          id: 5,
          date: '12/10/2022',
          iboNumber: 123460,
          orderType: 'BF',
          amount: 1238
        },
      ]
    }
  },
  methods: {
    moveTo(endpoint) {
      this.$router.push(endpoint);
    },
    formatCurrency(number) {
      const options = {style: 'currency', currency: 'USD'};
      const numberFormat = new Intl.NumberFormat('en-US', options);
      return numberFormat.format(number);
    },
    formatNumber(number, decimals) {
      //return number.toLocaleString("en-US");
      let options = {};
      if(decimals) {
        options = {style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2};
      }
      const numberFormat = new Intl.NumberFormat('en-US', options);
      return numberFormat.format(number);
    },
  },
  async mounted() {
    console.log('Store: ', this.$store.getters.showDrawer);
    this.$store.dispatch('updatePageTitle', 'Inicio');
    
  }
});
</script>

<style scoped>
  .main-background {
    background-color: white;
    margin-top: 45px;
  }

  .dashboard-btn {
    background-color: #8B4B01;
    color: white;
    flex-direction: column !important;
    align-items: center;
    width: 150px;
    height: 100px;
    border-radius: 5px;    
  }
</style>
