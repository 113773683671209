<template>
        <v-navigation-drawer
          :model-value="showDrawer"
          :rail="rail"
          rail-width="70"
          color="white"
          border="0"
          class="nav-drawer"
          
        >
          <v-list-item
            prepend-icon="fa-solid fa-gem"
            title="Orvil Mauricio"
            subtitle="Diamante"
            nav
            class="ml-3"
            lines="3"
            variant="flat"
          >
            <template v-slot:append>
              <v-btn
                variant="text"
                icon="fa-solid fa-xmark"
                @click.prevent="closeDrawer"
              ></v-btn>
            </template>
          </v-list-item>
          <v-card flat color="#8B4B01" class="rounded-xl mx-1 mt-10 side-bar">
            <v-list density="compact" nav rounded>
              <v-list-item v-for="(item, i) in items"
                :key="i"
                :prepend-icon="item.icon"
                :title="item.title"
                :value="i"
                rounded="xl"
                @click="menuClick(item.ruta)"
                density="compact"
                color="#FEF17F"
              >
              </v-list-item>
            </v-list>
          </v-card>
        </v-navigation-drawer>
</template>

<script>
  export default {
    data () {
      return {
        // drawer: false,
        items: [
          { title: 'Inicio', icon: 'fa-solid fa-house', ruta: '/' },
          { title: 'IBO', icon: 'fa-solid fa-user', ruta: '/ibo' },
          { title: 'Calendario', icon: 'fa-solid fa-calendar-days', ruta: '/calendar' },
          { title: 'Tienda', icon: 'fa-solid fa-cart-shopping', ruta: '/store' },
          { title: 'Reportes', icon: 'fa-solid fa-file-lines', ruta: '/reports' },
          { title: 'Mis Audios', icon: 'fa-solid fa-music', ruta: '/audios' },
          { title: 'Reproductor', icon: 'fa-solid fa-play', ruta: '/player' },
          { title: 'Salir', icon: 'fa-solid fa-right-from-bracket', ruta: '/logout' },
        ],
        rail: false,
      }
    },
    methods: {
      menuClick(index) {
        console.log(index);
        this.$store.dispatch('cancelShowDrawer');
        this.$router.push(index);
      },
      closeDrawer() {
        // this.drawer = false;
        // this.rail = !this.rail;
        this.$store.dispatch('updateShowDrawer');
      }
    },
    computed: {
      showDrawer() {
        return this.$store.getters.showDrawer;
      }
    }
  }
</script>

<style scoped>
  .side-bar {
    width: auto;
    color: #FEF17F;
    border-color: white;
  }

  .nav-drawer {
    z-index: 1 !important;
  }
</style>