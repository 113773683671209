import { createStore } from 'vuex'

export default createStore({
  state: {
    showDrawer: true,
    pageTitle: '',
    shoppingCartProducts: [],
  },
  getters: {
    showDrawer(state) {
      return state.showDrawer
    },
    pageTitle(state) {
      return 'Rhinos - ' + state.pageTitle
    },
    shoppingCartProducts(state) {
      return state.shoppingCartProducts
    },
    productsOnCart(state) {
      return state.shoppingCartProducts.length
    },
  },
  mutations: {
    updateShowDrawer(state) {
      state.showDrawer = !state.showDrawer
    },
    updatePageTitle(state, payload) {
      state.pageTitle = payload
    },
    cancelShowDrawer(state) {
      state.showDrawer = false
    },
    updateShoppingCartProducts(state, payload) {
      state.shoppingCartProducts.push(payload)
    },
  },
  actions: {
    async updateShowDrawer({ commit }) {
      commit('updateShowDrawer')
    },
    async updatePageTitle({ commit }, payload) {
      commit('updatePageTitle', payload)
    },
    async cancelShowDrawer({ commit }) {
      commit('cancelShowDrawer')
    },
    async updateShoppingCartProducts({ commit }, payload) {
      commit('updateShoppingCartProducts', payload)
    },
  },
  modules: {
  }
})
